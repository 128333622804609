body {
  background: #def;
}

#editor {
  padding: 40px;
}

#stage {
  border: solid 1px #ccc;
  background: white;
  display: inline-block;
}

#text-input {
  position: absolute;
  padding: 8px;
}

#text-input {
  font-family: monospace;
  font-size: 1.4rem;
}

#stage > div.text p {
  pointer-events: none;
}

.mv_l0 .box-block,
.mv_l2 .box-block {
  position: relative;
}

.mv_l0 .box-element {
  float: left;
  clear: left;

  &:first-child {
    clear: none;
  }
}

#composer {
  margin-right: 80px;
  margin: 0 100px 0 20px;

  #toolbars {
    position: absolute;
    pointer-events: none;
    z-index: 4;

    & > div {
      pointer-events: auto;
    }
  }

  .ProseMirror .placeholder {
    pointer-events: none;
    opacity: 0.4;
  }

  .ProseMirror:focus .placeholder {
    height: 1px;
  }

  .mario > div {
    outline: 0;
  }
}

#preview {
  padding-bottom: 120px;

  .pen {
    position: absolute;
    background: white;
    z-index: 2;
    color: #2241E0;

    & > *:first-child {
      margin-top: 0;
    }

    & > *:last-child {
      margin-bottom: 0;
    }
  }

  & > .box {
    display: inline-block;
  }

  .box-element {
    touch-action: none;
    outline: 0;

    & > * {
      pointer-events: none;
    }
  }

  .box-block {
    min-height: 40px;
  }
}

.selection {
  border: solid 1px #16470040;
  position: absolute;
  pointer-events: none;
  transform: translate(-2px, -2px);
  z-index: 3;
  
  & > svg {
    position: absolute;
    overflow: visible;

    &:nth-child(1) {
      top: 0;
      left: 100%;
    }

    &:nth-child(2) {
      top: 100%;
      left: 100%;
    }

    &:nth-child(3) {
      top: 100%;
      left: 0;
    }
  }
}

#props {
  position: absolute;
  text-align: center;

  div.icons {
    display: inline-block;
    
    & > * {
      padding: 2px;
      display: inline-block;
      background: #eee;
      border: solid 1px black;
      width: 22px;
      height: 22px;
    }
  }
}

#createbar {
  position: sticky;
  top: 10px;
  margin-left: 800px;

  div.stay {
    position: absolute;
    background: var(--mvt-toolback);
    border-radius: 12px;
    padding: 10px;
    font-family: 'Roboto Slab';
  }

  div.create {
    padding: 10px 0 16px 0;

    button {
      display: block;
      outline: 0;
      border: none;
      background: transparent;
      cursor: grab;
      color: inherit;
      touch-action: none;

      & > * {
        pointer-events: none;
      }

      svg {
        width: 48px;
      }
    }

    p {
      font-size: 0.7rem;
      text-align: center;
      margin: 0;
      color: inherit;
    }
  }
}

.ghost {
  position: absolute;
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  opacity: 0.7;
  pointer-events: none;
  touch-action: none;
  cursor: grabbing;

  & > * {
    pointer-events: none;
  }

  p {
    font-family: 'Helvetica Neue';
    font-size: 0.8rem;
    margin: 0;
    padding: 6px 14px;
  }
}

.banner {
  .publish {
    font-family: inherit;
    outline: 0;
    border: none;
    background: transparent;
    color: inherit;
    font-weight: bold;
    font-size: 1.1rem;
    cursor: pointer;
  }
}

#publish {
	.groups {
		pointer-events: none;
		opacity: 0.5;

		&.groups-public {
			pointer-events: auto;
			opacity: 1.0;
		}
	}

	.note {
		font-size: 0.8em;
	}

  ul {
    list-style: none;
    padding: 0;

    & > li {
      display: grid;
      grid-template-columns: 24px auto;
      padding: 6px;
      border-bottom: solid 1px;

      &:last-child {
        border-bottom: none;
      }

      p {
        margin: 2px 0;

        label {
          display: block;
          cursor: pointer;
        }
      }
    }

		[type=radio] { 
			position: absolute;
			opacity: 0;
			width: 0;
			height: 0;

			& + i {
				cursor: pointer;

				&:after {
          content: "";
          width: 24px;
          display: inline-block;
        }
			}
		} 

		[type=radio]:checked + i:after {
			content: "\2713";
		} 
  }
}
