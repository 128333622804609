@import '../fonts/Bungee.css';
@import './colors.scss';

#surface {
  width: 100%;
  
  #rect {
    width: 70%;
    height: 500px;
    background-image: linear-gradient(0deg, #D7D7D780 0%, #B7B7B780 75%);
    display: none;
  }
}

#toolbars {
  width: 404px;
}

#box {
  .box-block {
     width: auto;
  }
}

#prev {
  text-align: center;
  width: 54px;
  margin: 8px 0;

  a {
    font-size: 12px;
    text-decoration: none;
    margin: 4px 0;
    display: inline-block;
  }

  img {
    display: block;
    padding: 14px 14px 14px 10px;
    border: solid 2px $tool-border;
    border-radius: 4px;
    background: $tool-fill;
    width: 24px;

    &:hover {
      background: $tool-choice;
    }
  }
}

#next > div {
  text-align: center;

  a {
    display: inline-block;
    padding: 10px 40px;
    border: solid 2px $tool-border;
    margin: 40px;
    border-radius: 4px;
    text-decoration: none;
    background: $tool-fill;

    &:hover {
      background: #DBF3FD;
    }
  }
}

#copy-to-clipboard {
}
